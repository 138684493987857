<template>
    <v-container fluid pt-0>
        <v-row v-if="commission && commission.status === 'Approved'">
            <v-col class="pt-0 pb-0">
                <v-alert color="success" class="mb-0">
                    <v-icon class="mr-2">
                        mdi-alert-outline
                    </v-icon>
                    You cannot make any changes, because this has already been approved
                </v-alert>
            </v-col>
        </v-row>
        <v-row>
            <v-col md="3">
                <v-card>
                    <v-toolbar>
                        <v-toolbar-title>
                            <v-icon class="mr-2">
                                mdi-card-bulleted-outline
                            </v-icon>
                            Details
                        </v-toolbar-title>
                        <v-spacer>
                        </v-spacer>
                        <small class="grey--text">
                            Added @ {{ new Date(commission.dateAdded).toLocaleString() }}
                        </small>
                    </v-toolbar>
                    <v-card-text class="pb-0">
                        <h6>
                            {{ commission.zohoDealName }}
                        </h6>
                    </v-card-text>
                    <v-row>
                        <v-col class="pt-0">
                            <v-list>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            #{{ commission.invoiceNumber }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            Invoice Number
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ (commission.quoteNumber ? commission.quoteNumber : 'None Set') }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            Quote Number
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ commission.poNumber }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            PO Number
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-col>
                        <v-col class="pt-0">
                            <v-list>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            £{{ commission.costPrice }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            Cost Price
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            £{{ commission.poSalesValue }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            PO Sales Value
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            £{{ commission.vatSalesValue }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            VAT Sales Price
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                    <v-divider
                        v-if="($store.getters.hasScope('Commission.Modify') || $store.getters.hasScope('Commission.Approver')) && commission.status != 'Approved' && commission.status != 'Finalised'"></v-divider>
                    <v-card-text
                        v-if="$store.getters.hasScope('Commission.Modify') && commission.status != 'Approved' && commission.status != 'Finalised'">
                        <v-btn block small color="warning"
                            :to="{ name: 'Add Commission', params: { commissionId: commission.id } }">
                            <v-icon class="mr-2">
                                mdi-pencil
                            </v-icon>
                            Edit Commission
                        </v-btn>
                        <v-btn block small color="success" class="mt-2" @click="isShowingActionCommissionDialog = true"
                            v-if="$store.getters.hasScope('Commission.Approver')">
                            <v-icon class="mr-2">
                                mdi-triangle-outline
                            </v-icon>
                            Set commission status
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col md="6">
                <v-card>
                    <v-toolbar>
                        <v-toolbar-title>
                            <v-icon class="mr-2">
                                mdi-credit-card-wireless-outline
                            </v-icon>
                            Commission Request: {{ commission.addedBy }}
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-timeline align-top dense>
                        <v-timeline-item color="success" fill-dot icon="mdi-pencil-plus-outline"
                            v-if="$store.getters.hasScope('Commission.Modify') && commission.status == 'Awaiting Approval'">
                            <v-row class="mt-n2">
                                <v-col>
                                    <v-btn color="primary" small @click="isShowingNewNote = true">
                                        <v-icon class="mr-2">
                                            mdi-plus
                                        </v-icon>
                                        Add Note
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-timeline-item>
                        <v-timeline-item v-for="(item, index) in allCommissionItems" :key="index" :color="item.item.colour"
                            :icon="item.type == 'note' ? 'mdi-pencil-circle-outline' : item.item.icon" fill-dot>
                            <v-row class="mt-n3">
                                <v-col>
                                    <div v-if="item.type == 'note'">
                                        <strong>
                                            Note
                                        </strong>
                                        <p class="grey--text">
                                            {{ item.item.note }}
                                        </p>
                                    </div>
                                    <strong v-else>
                                        {{ item.item.text }}
                                    </strong>
                                    <div class="text-caption grey--text text--lighten-1">
                                        <v-icon class="mr-1 grey--text text--lighten-1" x-small>mdi-account</v-icon>{{
                                            item.item.addedBy }}<v-icon class="mr-1 ml-1 grey--text text--lighten-1" x-small>
                                            mdi-calendar-month-outline
                                        </v-icon>{{ new
                                            Date(item.item.dateAdded).toLocaleString() }}
                                    </div>
                                </v-col>
                            </v-row>
                        </v-timeline-item>
                    </v-timeline>
                </v-card>
            </v-col>
            <v-col md="3">
                <v-card>
                    <v-toolbar>
                        <v-toolbar-title>
                            <v-icon class="mr-2">
                                mdi-list-status
                            </v-icon>
                            <span :class="statusColor + '--text'">
                                {{ commission.status }}
                            </span>
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-list>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <h4 class="info--text text--lighten-2">
                                        £{{ totalCommission }}
                                    </h4>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    Total Commission
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <h4 class="info--text text--lighten-2">
                                        {{ commission.rate }}%
                                    </h4>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    Rate
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <h5 class="info--text text--lighten-2">
                                        {{ commission.type }}
                                    </h5>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    Type
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <v-btn block info
                                        :to="{ name: 'Company Dashboard', params: { companyId: forCompany.id } }">
                                        <v-icon class="mr-2">
                                            mdi-office-building-outline
                                        </v-icon>
                                        Company: {{ forCompany.name }}
                                    </v-btn>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="commission.invoiceId">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <v-btn block target="blank"
                                        :href="'https://go.xero.com/AccountsReceivable/Edit.aspx?InvoiceID=' + commission.invoiceId">
                                        <v-icon class="mr-2">
                                            mdi-open-in-new
                                        </v-icon>
                                        Open Xero Invoice: #{{ commission.invoiceNumber }}
                                    </v-btn>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn block v-on="on" v-bind="attrs" target="blank"
                                                :href="'https://crm.zoho.eu/crm/org20068100338/tab/Potentials/' + commission.zohoDealId">
                                                <v-icon class="mr-2">
                                                    mdi-open-in-new
                                                </v-icon>
                                                Open Zoho Deal
                                            </v-btn>
                                        </template>
                                        <span>
                                            {{ commission.zohoDealName }}
                                        </span>
                                    </v-tooltip>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="isShowingNewNote" max-width="60vh" @click:outside="cancelNewCommissionNote">
            <v-card>
                <v-toolbar>
                    <v-toolbar-title>
                        <v-icon class="mr-2">
                            mdi-plus
                        </v-icon>
                        Add New Note
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-textarea label="New Note Text" v-model="newCommissionNote">
                    </v-textarea>
                    <v-btn :disabled="!newCommissionNote" color="primary" block @click="addNewCommissionNote">
                        <v-icon class="mr-2">
                            mdi-floppy
                        </v-icon>
                        Save Note
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
        <trev-commission-action-dialog :commission="commission" :showDialog="isShowingActionCommissionDialog"
            @actioned="getCommission();" @added-note="getCommissionNotes();"></trev-commission-action-dialog>
    </v-container>
</template>

<script>
import trevCommissionActionDialog from '../../../../components/commission/trev-commission-action.dialog.vue';
import * as GeneralUtilities from '../../../../utilities/GeneralUtilities';

export default {
    components: { trevCommissionActionDialog },
    created() {
        this.getCommission();
    },
    computed: {
        allCommissionItems() {
            var total = [];

            if (this.commissionActions && this.commissionActions.length > 0) {
                total = total.concat(this.commissionActions.map(x => ({ type: 'action', item: x })));
            }

            if (this.commissionNotes && this.commissionNotes.length > 0) {
                total = total.concat(this.commissionNotes.map(x => ({ type: 'note', item: x })));
            }

            total = total.sort((first, second) => new Date(second.item.dateAdded) - new Date(first.item.dateAdded));

            return total;
        },
        totalCommission() {
            if (this.commission.id) {
                return GeneralUtilities.getCommissionFromObj(this.commission);
            } else {
                return 0;
            }
        },
        statusColor() {
            if (this.commission.status === 'Awaiting Approval') {
                return 'warning';
            } else if (this.commission.status === 'Approved') {
                return 'success';
            } else if (this.commission.status === 'Denied') {
                return 'danger';
            }
        }
    },
    methods: {
        getCommission() {
            this.$courier.Commissions.getById(this.$route.params.commissionId).then(commission => {
                this.commission = commission;
                this.getCommissionCompany();
            });

            this.getCommissionActions();
            this.getCommissionNotes();
        },
        getCommissionCompany() {
            this.$courier.Companies.getById(this.commission.forCompanyId).then(company => {
                this.forCompany = company;
            });
        },
        getCommissionActions() {
            this.$courier.Commissions.withId(this.$route.params.commissionId).TimeLine.getData(1, 9999, '').then(response => {
                if (response && response.payload && response.payload.length > 0) {
                    this.commissionActions = response.payload;
                }
            });
        },
        getCommissionNotes() {
            this.$courier.Commissions.withId(this.$route.params.commissionId).Notes.getData(1, 9999, '').then(response => {
                if (response && response.payload && response.payload.length > 0) {
                    this.commissionNotes = response.payload;
                }
            });
        },
        addNewCommissionNote() {
            if (this.newCommissionNote) {
                this.$courier.Commissions.withId(this.$route.params.commissionId).Notes.add({
                    note: this.newCommissionNote
                }).then(newCommissionNote => {
                    this.commissionNotes.push(newCommissionNote);

                    this.cancelNewCommissionNote();
                });
            }
        },
        cancelNewCommissionNote() {
            this.isShowingNewNote = false;
            this.newCommissionNote = '';
        }
    },
    data() {
        return {
            isShowingActionCommissionDialog: false,
            isShowingNewNote: false,
            newCommissionNote: '',
            commission: {},
            forCompany: {},
            commissionActions: [],
            commissionNotes: []
        }
    },
}
</script>

<style></style>